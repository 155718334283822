import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Senior Software Developer",
          "NodeJs | NestJs | Python | C++ | Java",
          "Kafka | RabbitMq | BullMq | NatsMQ",
          "Docker | Jenkins ",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 2,
      }}
    />
  );
}

export default Type;
