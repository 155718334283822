import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Akhil Kumar Singh </span>
            from <span className="purple"> Bangalore, India.</span>
            <br />I am currently employed as a senior software developer at{" "}
            <span className="purple">
              <a href="https://www.safeai.ai/">SafeAi</a>
            </span>
            .
            <br />I have completed Bachelor in Technology (b.tech) from{" "}
            <span className="purple">
              <a href="https://www.iiita.ac.in/">IIIT-Allahabad</a>
            </span>
            .
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Valorant & CSGO
            </li>
            <li className="about-activity">
              <ImPointRight /> Watch youtube videos
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">AKS</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
